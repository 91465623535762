let jquery = require("jquery");
let jqueryUi = require("jquery-ui");
window.$ = window.jQuery = jquery;

document.addEventListener("DOMContentLoaded", function () {
    $("#mobile-menu-close").on('click', function () {
        $("#mobile-menu").fadeOut();
        $(this).fadeOut();
        $("#mobile-menu-button").fadeIn();
    });

    $("#mobile-menu-button").on('click', function () {
        $("#mobile-menu").show( "slow");
        $(this).fadeOut();
        $("#mobile-menu-close").fadeIn();
        $('#mobile-menu-button').hide("slow");
    });

    $('.initial').addClass('purple-text');
    $(".menu-item").on("click", function (e) {
        $(".menu-item").each(function (index) {
            $(this).removeClass("purple-text");
        });
        $(this).addClass("purple-text");
    });

    window.onscroll = function () {
        setStickyListener();
    };

});

function setStickyListener() {
    const num = 80;
    const button = $('#download-button').offset().top;
    $(window).bind('scroll', function () {
            if ($(window).scrollTop() > num) {
                $('.navbar').addClass('sticky');
                $('#mobile-menu').addClass('sticky-mobile');
                if ($(window).scrollTop() > button) {
                    $('#download-button-menu').removeClass('display-none');
                } else {
                    $('#download-button-menu').addClass('display-none');
                }
            } else {
                $('.navbar').removeClass('sticky');
                $('#mobile-menu').addClass('sticky-mobile');
            }

        }
    )
    ;
}